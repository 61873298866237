<template>
  <div>
    <div class="header">
      <div class="header-title">
        <img src="../../../assets/mobile/log.png" @click="handleChange('1')" />
        <img src="../../../assets/mobile/head_img2.png" @click="isShow = !isShow" />
      </div>
    </div>
    <van-overlay :show="isShow" z-index="999999" @click="isShow = false">
      <div class="header-title" style="height: 55px; background: #fff" @click.stop>
        <img src="../../../assets/mobile/log.png" @click="handleChange('1')" />
        <!-- <img
          src="../../../assets/mobile/head_img2.png"
          @click="isShow = !isShow"
        /> -->
        <span style="width: 46px; font-size: 24px; color: #3575bc" @click="isShow = !isShow">x</span>
      </div>
      <div class="header-nav" v-show="isShow" @click.stop>
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item title="首页" name="1"> </el-collapse-item>
          <!-- <el-collapse-item title="产品服务" name="2"> </el-collapse-item> -->
          <el-collapse-item title="产品及服务">
            <div class="nav-item" @click="toNav('2-1')">
              <span>智能产品</span>
            </div>
            <div class="nav-item" @click="toNav('2-2')">
              <span>AI算法</span>
            </div>
            <div class="nav-item" @click="toNav('2-3')">
              <span>平台软件</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="核心技术">
            <div class="nav-item" @click="toNav('3-1')">
              <span>视觉搜索</span>
            </div>
            <div class="nav-item" @click="toNav('3-2')">
              <span>社交媒体</span>
            </div>
            <div class="nav-item" @click="toNav('3-3')">
              <span>图像理解</span>
            </div>
            <div class="nav-item" @click="toNav('3-4')">
              <span>机器学习</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="解决方案">
            <div class="nav-item" @click="toNav('4-1')">
              <span>智慧园区</span>
            </div>
            <div class="nav-item" @click="toNav('4-2')">
              <span>智慧交通</span>
            </div>
            <div class="nav-item" @click="toNav('4-3')">
              <span>布控稽查</span>
            </div>
            <div class="nav-item" @click="toNav('4-4')">
              <span>市域治理</span>
            </div>
          </el-collapse-item>
          <el-collapse-item title="关于我们" name="5"> </el-collapse-item>
        </el-collapse>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import '../../static/js/phone'
export default {
  data() {
    return {
      isShow: false,
      activeNames: "",
      show: false,
    };
  },
  methods: {
    handleChange(index) {
      if (index == 1) {
        this.$router.push({ path: "/mobile/home" });
      } else if (index == 5) {
        this.$router.push({ path: "/mobile/maboutMe" });
      }
      if (index == 1 || index == 5) {
        this.isShow = false;
      }
    },
    toNav(index) {
      if (index == "3-1") {
        this.$router.push({ path: "/mtechnology", query: { index: 0 } });
      } else if (index == "3-2") {
        this.$router.push({ path: "/mtechnology", query: { index: 1 } });
      } else if (index == "3-3") {
        this.$router.push({ path: "/mtechnology", query: { index: 2 } });
      } else if (index == "3-4") {
        this.$router.push({ path: "/mtechnology", query: { index: 3 } });
      } else if (index == "4-1") {
        this.$router.push({ path: "/msolution", query: { index: 1 } });
      } else if (index == "4-2") {
        this.$router.push({ path: "/msolution", query: { index: 2 } });
      } else if (index == "4-3") {
        this.$router.push({ path: "/msolution", query: { index: 3 } });
      } else if (index == "4-4") {
        this.$router.push({ path: "/msolution", query: { index: 4 } });
      } else if (index == "2-1") {
        this.$router.push({ path: "/mobile/product", query: { index: 0 } });
      } else if (index == "2-2") {
        this.$router.push({ path: "/mobile/product", query: { index: 1 } });
      } else if (index == "2-3") {
        this.$router.push({ path: "/mobile/product", query: { index: 2 } });
      }
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}

:deep(.el-collapse > div:nth-child(1) .el-icon-arrow-right:before) {
  display: none;
}

:deep(.el-collapse > div:nth-child(1) .el-collapse-item__content) {
  display: none;
}

// :deep(.el-collapse > div:nth-child(2) .el-icon-arrow-right:before) {
//   display: none;
// }
// :deep(.el-collapse > div:nth-child(2) .el-collapse-item__content) {
//   display: none;
// }
:deep(.el-collapse > div:nth-child(5) .el-icon-arrow-right:before) {
  display: none;
}

:deep(.el-collapse > div:nth-child(5) .el-collapse-item__content) {
  display: none;
}

.header {
  // background-color: rgba(237, 244, 255, 0.6);
  // background-color: rgba(237, 244, 255, 1);
  background: #fff;
  // opacity: 0.502;
  width: 100%;
  height: 48px;
  // height: 88px;
  position: fixed;
  z-index: 999;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #ffffff1f;

  .header-nav {
    :deep(.el-collapse-item__header) {
      font-size: 16px;
      font-family: "PingFang";
      color: rgb(85, 85, 85);
      padding-left: 20px;
    }

    .nav-item {
      width: 100%;
      height: 50px;
      background-color: rgb(245, 245, 245);
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        font-family: "PingFang";
        color: rgb(85, 85, 85);
        margin-left: 40px;
      }
    }

    .nav-item:hover {
      background-color: #b3b1b1c9;
    }
  }

  .header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    img {
      height: 50%;
    }

    img:nth-child(1) {
      margin-left: 20px;
      // width: 56px;
      height: 40px;
    }

    img:nth-child(2) {
      margin-right: 20px;
      height: 20px;
    }
  }
}

.header-nav {
  :deep(.el-collapse-item__header) {
    font-size: 16px;
    font-family: "PingFang";
    color: rgb(85, 85, 85);
    padding-left: 20px;
  }

  .nav-item {
    width: 100%;
    height: 50px;
    background-color: rgb(245, 245, 245);
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      font-family: "PingFang";
      color: rgb(85, 85, 85);
      margin-left: 40px;
    }
  }

  .nav-item:hover {
    background-color: #b3b1b1c9;
  }
}

.header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  img {
    height: 50%;
  }

  img:nth-child(1) {
    margin-left: 20px;
    // width: 56px;
    height: 40px;
  }

  img:nth-child(2) {
    margin-right: 20px;
    height: 20px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
