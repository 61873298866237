import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import mobile from "@/views/mobile";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/homePage",
    children: [
      {
        path: "homePage",
        component: () => import("@/views/homePage"),
      },
      {
        path: "coreTechnology",
        component: () => import("@/views/coreTechnology"),
      },
      {
        path: "solution",
        component: () => import("@/views/solution"),
      },
      {
        path: "intellectualProducts",
        component: () => import("@/views/intellectualProducts"),
      },
      {
        path: "aboutUs",
        component: () => import("@/views/aboutUs"),
      },
    ],
  },
  {
    path: "/mobile",
    component: mobile,
    redirect: "/mobile/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/mobile/homeItem.vue"),
        meta: { title: "首页-南强智视" },
      },
      {
        path: "/msolution",
        component: () => import("@/views/mobile/solution.vue"),
      },
      {
        path: "/mtechnology",
        component: () => import("@/views/mobile/technology.vue"),
      },
      {
        path: "product",
        component: () => import("@/views/mobile/components/product.vue"),
      },
      {
        path: "maboutMe",
        component: () => import("@/views/mobile/components/maboutMe.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  //  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
