<template>
  <div>
    <phead></phead>
    <router-view />
    <feet></feet>
  </div>
</template>

<script>
import phead from "./components/mhead.vue";
import feet from "./components/mfeet.vue";
export default {
  components: {
    phead,
    feet,
  },
};
</script>

<style lang="scss" scoped>
</style>