<template>
  <div class="box">
    <div class="titles">
      <img src="../../../assets/mobile/log.png" alt="" />
      <p>AI智能视觉全方案服务提供商</p>
    </div>
    <div class="information">
      <p>地址：福建省厦门市思明区软件园一期创新大厦A区12楼</p>
      <p>联系电话：0592-2184931</p>
      <p style="margin-bottom: 10px">工作时间：周一至周五 9:00-18:00</p>
    </div>
    <div class="copyright">
      <p>版权所有 ©南强智视（厦门）科技有限公司</p>
      <a href="https://beian.miit.gov.cn">闽ICP备20008435号-{{ num }}</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { num: "1" };
  },
  methods: {
    toGo() {
      window.location.href = "https://beian.miit.gov.cn";
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
  },
  mounted() {
    let href = window.location.href;
    // let href = 'www.nanqiang.cn'
    console.log(href);
    let str = "nanqiang";
    let index = href.indexOf(str);
    console.log(index);
    if (index === -1) {
      console.log("nqiv");
      this.num = "1";
    } else {
      console.log("有找到");
      this.num = "2";
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  // height: 150px;
  background-color: #eff5f9;
  padding: 15px 0 15px 15px;
  box-sizing: border-box;
  text-align: left;

  .titles {
    display: flex;
    align-items: center;

    img {
      // width: 60px;
      height: 40px;
      margin-right: 15px;
    }

    p {
      letter-spacing: 2px;
      color: #333333;
      font-size: 14px;
    }
  }

  .information {
    margin-top: 15px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 0.7;
  }

  .copyright {
    padding-top: 10px;
    border-top: 1px solid rgb(233, 231, 231);

    p {
      // width: 311px;
      // height: 10px;
      line-height: 12px;
      letter-spacing: 0px;
      color: #333333;
      opacity: 0.7;
      font-size: 12px;
      margin-bottom: 7px;
    }

    a {
      text-decoration: none;
      line-height: 7px;
      letter-spacing: 0px;
      color: #333333;
      opacity: 0.7;
      font-size: 12px;
    }
  }
}
</style>
