import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import Vant from "vant";
import "./style/reset.css";
//引入swiper样式
import "swiper/css/swiper.css";
import "element-ui/lib/theme-chalk/index.css";
import "vant/lib/index.css";

// 懒加载
import VueLazyload from "vue-lazyload";

Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.use(Vant);

//定义全局组件：在入口文件注册一次之后，在任何组件当中都可以使用
import Carousel from "@/views/components/Carousel";
Vue.component("Carousel", Carousel);
document.title = "南强智视";
router.beforeEach((to, from, next) => {
  if (to.fullPath === "/mobile/product?index=0") {
    to.meta.title = "智能产品-南强智视";
  } else if (to.fullPath === "/mobile/product?index=1") {
    to.meta.title = "Ai算法-南强智视";
  } else if (to.fullPath === "/mobile/product?index=2") {
    to.meta.title = "平台软件-南强智视";
  } else if (to.fullPath === "/mtechnology?index=0") {
    to.meta.title = "视觉探索-南强智视";
  } else if (to.fullPath === "/mtechnology?index=1") {
    to.meta.title = "社交媒体-南强智视";
  } else if (to.fullPath === "/mtechnology?index=2") {
    to.meta.title = "图像理解-南强智视";
  } else if (to.fullPath === "/mtechnology?index=3") {
    to.meta.title = "机器学习-南强智视";
  } else if (to.fullPath === "/msolution?index=1") {
    to.meta.title = "智慧园区-南强智视";
  } else if (to.fullPath === "/msolution?index=2") {
    to.meta.title = "智慧交通-南强智视";
  } else if (to.fullPath === "/msolution?index=3") {
    to.meta.title = "布控稽查-南强智视";
  } else if (to.fullPath === "/msolution?index=4") {
    to.meta.title = "市域治理-南强智视";
  } else if (to.fullPath === "/mobile/maboutMe") {
    to.meta.title = "关于我们-南强智视";
  }

  document.title = to.meta.title ? to.meta.title : "南强智视";
  // document.title = "南强智视";
  console.log("to", to);
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
